<template>
  <b-row class="match-height">
    <b-card>
      <h2>
        Type de licence: <span class="text-success"> {{ licence_type }}</span>
      </h2>
      <hr />
      <h4>Jours Total: {{ days }}</h4>
      <h5>Statut Actuel: {{ status }}</h5>
      <h6>Descriptions: {{ description }}</h6>
    </b-card>
    <b-card style="margin-left: 10px">
      <h2>Modules:</h2>
      <hr />
      <h4>{{ modules }}</h4>
    </b-card>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";
import axios from "axios";

export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {
      sku_corporation: "1",
      licence_type: "-",
      modules: "-",
      days: "-",
      status: "-",
      description: "-",
    };
  },
  mounted() {
    this.getCorporationInfos();
  },
  methods: {
    async getCorporationInfos() {
      const final_response = await axios
        .get(
          $themeConfig.app.api_endpoint +
            `get_licence_infos/${$themeConfig.app.api_endpoint_access_token}/${this.sku_corporation}`
        )
        .then((response) => {
          var licence_data = response.data.licenceInfos;
          if (licence_data != null) {
            this.licence_type = licence_data.licence_type;
            this.modules = licence_data.modules;
            this.days = licence_data.days;
            this.status = licence_data.status;
            this.description = licence_data.description;
          } else {
          }
        })
        .catch((error) => {
          console.log(error);
        });
      return final_response;
    },
  },
};
</script>
